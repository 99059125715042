/// <reference types="tsconfig/segment" />
import { Brand, BrandId } from './types';
import { trackAnalyticsEvent } from './track-analytics-event';

type TrackEvent<EventName extends string, Properties extends object> = {
  name: EventName;
  properties: Properties;
};

export type EventMap = {
  [Event in Events as Event['name']]: Event['properties'];
};

export function trackEvent<K extends keyof EventMap>(
  eventName: K,
  properties?: EventMap[K],
) {
  trackAnalyticsEvent(eventName, properties);
}
type Events =
  | AdditionalTreatmentSurveyStarted
  | AddMedicationClicked
  | AgreementsAccepted
  | AgreementSkipped
  | AgreementsSignOutClicked
  | AgreementsViewed
  | AppointmentBooked
  | ButtonClicked
  | CalendarAppointmentDetailsOpened
  | CalendarScalesAssessmentReviewClicked
  | CalendarScalesCopied
  | CalendarScalesToggled
  | CalendarViewChanged
  | CalendarViewPatientProfileClicked
  | ConsultPreferenceFormSubmitted
  | FlowFinalized
  | FlowLoaded
  | FormFlowEligible
  | FormFlowLoaded
  | FormFlowFinalized
  | FormRedirected
  | FormStepCompleted
  | FormStepLoaded
  | InAppSurveyCompleted
  | LabStepClicked
  | NavLinkClicked
  | NewRxRecordClicked
  | OverrideWorkflowStep
  | PillCountUpdated
  | RemoveMedicationClicked
  | ReprescribeClicked
  | ReviewProfileFormEntered
  | ReviewProfileFormSubmitted
  | ShortQuestionnaireStarted
  | StartPostConsultFormClicked
  | StepLoaded
  | StepCompleted
  | SubmitAddressFormEntered
  | SubmitAddressFormSubmitted
  | SubmitFridaPostConsultForm
  | SubmitFridaRxRecord
  | QuestionnaireCompleted
  | QuestionnairePageEntered
  | QuestionnaireStarted
  | ZoomAppointmentClicked
  | USQuestionnaireCompleted;

type AgreementsAccepted = TrackEvent<
  'agreementsAccepted',
  { agreementIds: string[]; brandId: BrandId }
>;

type AgreementSkipped = TrackEvent<
  'agreementSkipped',
  { agreementId: string; brandId: BrandId }
>;

type AgreementsSignOutClicked = TrackEvent<
  'agreementsSignOutClicked',
  { brandId: BrandId }
>;

type AgreementsViewed = TrackEvent<
  'agreementsViewed',
  { agreementIds: string[]; brandId: BrandId }
>;

type CalendarAppointmentDetailsOpened = TrackEvent<
  'calendarAppointmentDetailsOpened',
  AppointmentDrawerAttrs
>;

type CalendarViewChanged = TrackEvent<
  'calendarViewChanged',
  {
    selectedView: 'day' | 'week' | 'month';
    previousView: 'day' | 'week' | 'month';
    action: 'selectedFromDropdownList';
  }
>;

type CalendarViewPatientProfileClicked = TrackEvent<
  'calendarViewPatientProfileClicked',
  AppointmentDrawerAttrs
>;

type CalendarScalesToggled = TrackEvent<
  'calendarScalesToggled',
  AppointmentDrawerAttrs<{ open: boolean }>
>;

type CalendarScalesCopied = TrackEvent<
  'calendarScalesCopied',
  AppointmentDrawerAttrs
>;

type CalendarScalesAssessmentReviewClicked = TrackEvent<
  'calendarScalesAssessmentReviewClicked',
  AppointmentDrawerAttrs<{ assessmentId: string }>
>;

type ZoomAppointmentClicked = TrackEvent<
  'zoomAppointmentClicked',
  AppointmentDrawerAttrs<{
    appointmentReason: string | null;
    callType: 'phone' | 'video';
  }>
>;

type StartPostConsultFormClicked = TrackEvent<
  'startPostConsultFormClicked',
  AppointmentDrawerAttrs
>;

type NewRxRecordClicked = TrackEvent<
  'newRxRecordClicked',
  {
    brandId: BrandId;
    patientId: string | null;
  }
>;

type SubmitFridaRxRecord = TrackEvent<'submitFridaRxRecord', PrescriptionAttrs>;

type SubmitFridaPostConsultForm = TrackEvent<
  'submitFridaPostConsultForm',
  PrescriptionAttrs
>;

type AddMedicationClicked = TrackEvent<
  'addMedicationClicked',
  Record<string, never>
>;

type RemoveMedicationClicked = TrackEvent<
  'removeMedicationClicked',
  Record<string, never>
>;

type PillCountUpdated = TrackEvent<'Pill Count Updated', PillCountUpdatedAttrs>;

type AppointmentDrawerAttrs<T extends object = object> = T & {
  brandId: string | null;
  appointmentId: string;
  appointmentType: string | null;
  patientId: string | null;
};

type PrescriptionAttrs<T extends object = object> = T & {
  numberOfPrescriptions: number;
};

type QuestionnaireCompletedAttrs<T extends object = object> = T & {
  definitionId: string | null;
  responseId: string | null;
};

type QuestionnaireCompleted = TrackEvent<
  // Definition name should be used for string
  `${string} Completed`,
  QuestionnaireCompletedAttrs
>;

type QuestionnaireStartedAttrs<T extends object = object> = T & {
  responseId: string | null;
  workflowStepId?: string;
};

type QuestionnaireStarted = TrackEvent<
  // Definition name should be used for string
  `${string} Started`,
  QuestionnaireStartedAttrs
>;

type PillCountUpdatedAttrs<T extends object = object> = T & {
  pillCount: number;
};
type LabStepClickedAttrs<T extends object = object> = T & {
  workflowStepId: string;
};

type LabStepClicked = TrackEvent<`${string} Clicked`, LabStepClickedAttrs>;

type ShortQuestionnaireStartedAttrs<T extends object = object> = T & {
  workflowStepId?: string;
};

type ShortQuestionnaireStarted = TrackEvent<
  'Short Questionnaire Started',
  ShortQuestionnaireStartedAttrs
>;

type QuestionnairePageEnteredAttrs<T extends object = object> = T & {
  pageName?: string;
  questionnaireName?: string;
  brandMembership?: string;
};

type QuestionnairePageEntered = TrackEvent<
  'Questionnaire Page Entered',
  QuestionnairePageEnteredAttrs
>;

type ReviewProfileFormEntered = TrackEvent<
  'Review Profile Form Entered',
  never
>;

type ReviewProfileFormSubmitted = TrackEvent<
  'Review Profile Form Submitted',
  never
>;

type ConsultPreferenceFormSubmittedAttrs<T extends object = object> = T & {
  consultPreference: 'message' | 'phone';
};

type ConsultPreferenceFormSubmitted = TrackEvent<
  'Consult Preference Form Submitted',
  ConsultPreferenceFormSubmittedAttrs
>;

type SubmitAddressFormEntered = TrackEvent<
  'Shipping Address Form Entered',
  never
>;

type SubmitAddressFormSubmitted = TrackEvent<
  'Shipping Address Form Submitted',
  never
>;

type OverrideWorkflowStep = TrackEvent<
  'Override Workflow Step',
  { workflowStepId: string; workflowStepType: string; completionParams: object }
>;

type ReprescribeClickedAttrs<T extends object = object> = T & {
  brandId: string;
  prescriptionId: string;
};

type ReprescribeClicked = TrackEvent<
  'Re-prescribe Clicked',
  ReprescribeClickedAttrs
>;

type InAppSurveyCompleted = TrackEvent<
  'In App Survey',
  { question: string; answer: string }
>;

type AdditionalTreatmentSurveyStarted = TrackEvent<
  'Additional Treatment Survey Started',
  { treatment: string }
>;

type CountryCode = string;

type FlowLoaded = TrackEvent<'FlowLoaded', never>;
type FlowFinalized = TrackEvent<'FlowFinalized', never>;
type StepLoaded = TrackEvent<'StepLoaded', { answerLength: number }>;
type StepCompleted = TrackEvent<'StepCompleted', { answerLength: number }>;
type USQuestionnaireCompleted = TrackEvent<'USQuestionnaireCompleted', never>;
type AppointmentBooked = TrackEvent<
  `${CountryCode} Appointment Booked`,
  {
    brandName: string;
    contactType: string;
    countryCode: string;
  }
>;
type NavLinkClickedAttrs<T extends object = object> = T & {
  brandName: Brand;
  targetPath: string;
  currentPath: string;
};
type NavLinkClicked = TrackEvent<'Nav Link Clicked', NavLinkClickedAttrs>;

export type FormName =
  | 'US Intake Questionnaire'
  | 'US Initial Self-Report Questionnaire'
  | 'US Refill Questionnaire'
  | 'US HIV Care Intake Questionnaire';

type FormFlowLoaded = TrackEvent<`${FormName} Flow Loaded`, never>;

type FormFlowFinalized = TrackEvent<`${FormName} Flow Finalized`, never>;

type FormFlowEligible = TrackEvent<`${FormName} Eligible`, never>;

type FormStepAttrs<T extends object = object> = T & {
  answerLength: number;
};

type FormStepLoaded = TrackEvent<`${FormName} Step Loaded`, FormStepAttrs>;

export type FormStepCompleted = TrackEvent<
  `${FormName} Step Completed`,
  FormStepAttrs
>;

type FormRedirectedAttrs<T extends object = object> = T & {
  currentPath: string;
  targetPath: string;
};

type FormRedirected = TrackEvent<`${FormName} Redirected`, FormRedirectedAttrs>;

type ButtonClickedAttrs<T extends object = object> = T & {
  // The actual text on the button
  buttonText: string;
  // A internal name that won't change if text changes
  buttonName: string;
  // Target path of the page that will be navigated to if the button is a link
  targetPath?: string;
  // Current path of the page where the button was clicked (window.location.pathname)
  currentPath: string;
  // A internal name that won't change if the URL changes
  currentPageName: string;
};
type ButtonClicked = TrackEvent<'Button Clicked', ButtonClickedAttrs>;
