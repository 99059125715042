import { LegacyRef, ReactNode } from 'react';
import { ErrorMessage, useField } from 'formik';
import clsx from 'clsx';

export type SelectProps = {
  name: string;
  options: string[] | Array<{ text: ReactNode; value: string }>;
  disabled?: boolean;
  className?: string;
  cols?: number;
  dataCy?: string;
  innerRef?: LegacyRef<HTMLDivElement>;
  onSelect?: (value: string) => void;
};

export const Select = ({
  name,
  options,
  className,
  innerRef,
  onSelect,
  dataCy,
  disabled = false,
  cols = 1,
}: SelectProps) => {
  const [, { value }, { setValue }] = useField(name);

  let _options: Array<{ text: ReactNode; value: string }>;
  if (typeof options[0] === 'string') {
    _options = (options as string[]).map((option) => ({
      text: option,
      value: option,
    }));
  } else {
    _options = options as Array<{ text: ReactNode; value: string }>;
  }

  return (
    <div className="mt-4 w-full" ref={innerRef}>
      <div
        className={clsx(
          'mx-auto grid grid-cols-1 gap-x-4 gap-y-2',
          cols === 2 && 'sm:grid-cols-2',
          cols === 3 && 'sm:grid-cols-3',
        )}
        role="group"
        aria-labelledby={name}
      >
        {_options.map(
          (option: { text: ReactNode; value: string }, index: number) => (
            <button
              key={index}
              data-cy={`${dataCy}-${index}`}
              type="button"
              role="radio"
              aria-checked={value === option.value ? 'true' : 'false'}
              disabled={disabled}
              onClick={() => {
                setValue(option.value);
                if (onSelect) onSelect(option.value);
              }}
              className={clsx(
                'border-grey-500 w-full rounded-lg border px-5 py-4 transition duration-200 ease-in-out',
                value === option.value
                  ? 'bg-grey-600 text-white' // selected state
                  : 'text-grey-600 bg-white',
                className,
              )}
            >
              {option.text}
            </button>
          ),
        )}
      </div>
      <ErrorMessage name={name} component="p" className="mt-2 text-red-500" />
    </div>
  );
};
